import * as Sentry from '@sentry/react';
import ms from 'ms';
import { enqueueSnackbar } from 'notistack';
import { redirect } from 'react-router-dom';

import { getMe, GetMeOutput } from '@/api/auth/get-me';
import { getMyReseller } from '@/api/auth/get-my-reseller';
import { impersonate } from '@/api/auth/impersonate';
import { login } from '@/api/auth/login';
import { logout } from '@/api/auth/logout';
import { refresh } from '@/api/auth/refresh';
import { unimpersonate } from '@/api/auth/unimpersonate';
import { getUser } from '@/api/user/get-user';
import { mutateStatRoutes } from '@/api/util/mutate-route';
import { redirectEmitter } from '@/event';
import { userStore } from '@/store/user';
import { config, ENV } from '@/util/config';
import { handleError } from '@/util/handle-error';
import { changeLanguage, i18n, I18nNs } from '@/util/i18n';

type User = GetMeOutput;

const REFRESH_DELAY = '5m';

class UserManager {
  constructor() {
    this.logout = this.logout.bind(this);
    this.getMe = this.getMe.bind(this);
    this.login = this.login.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getUser = this.getUser.bind(this);
    this.impersonate = this.impersonate.bind(this);
    this.stopImpersonate = this.stopImpersonate.bind(this);
  }

  getUser(): User | null {
    return userStore.getState().user;
  }

  getToken(): string | null {
    return userStore.getState().token;
  }

  async stopImpersonate(): Promise<void> {
    if (!this.getUser()) return;

    const token = this.getToken();
    if (!token) return;

    mutateStatRoutes();

    const xsrf = await unimpersonate();
    if (!xsrf) return;

    userStore.setState({ token: xsrf });
    await this.getMe();
    mutateStatRoutes();

    redirectEmitter.emit('to', '/dashboard');
    enqueueSnackbar({
      message: `Vous êtes de retour sur votre compte.`,
      variant: 'success',
      preventDuplicate: true,
    });
  }

  async impersonate(user: { id: string; fullName: string }): Promise<void> {
    try {
      if (!this.getUser()) throw new Error('No user connected');
      if (!this.getToken()) throw new Error('No token found');
      if (!userStore.getState().isGod) throw new Error('User must has Super admin role');

      const userToImpersonate = await getUser(user.id);
      if (!userToImpersonate) {
        throw new Error('User to impersonate not found');
      }

      changeLanguage(userToImpersonate.lang);

      const xsrf = await impersonate(user.id);

      if (!xsrf) throw new Error('No xsrf return for api');

      userStore.setState({ token: xsrf });
      await this.getMe();

      mutateStatRoutes();
      redirectEmitter.emit('to', '/dashboard');

      enqueueSnackbar({
        message: i18n.t('impersonate.success', { name: user.fullName, ns: I18nNs.Alert }),
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar({
        message: i18n.t('impersonate.fail', { name: user.fullName, ns: I18nNs.Alert }),
        variant: 'error',
        preventDuplicate: true,
      });
    }
  }

  async refresh(isRetry = false): Promise<User | null> {
    if (!this.getToken()) return null;

    let x;
    try {
      x = await refresh(true);
    } catch (err) {
      handleError('/src/manager/user.manager', err);
      if (!isRetry) {
        return new Promise((resolve) => {
          setTimeout(() => this.refresh(true).then(resolve), 1000);
        });
      }

      await this.logout();
      return null;
    }

    if (!x) return this.logout();
    userStore.setState({ token: x });
    const me = userStore.getState().user;

    // Refresh user
    await this.getMe();

    return me;
  }

  async getMe(): Promise<User | null> {
    if (!this.getToken()) return this.logout();

    try {
      const [me, reseller] = await Promise.all([getMe(), getMyReseller()]);
      if (!me) return this.logout();
      userStore.setState({ user: me, reseller });
      return me;
    } catch (err) {
      handleError('/src/manager/user.manager', err);
      return this.logout();
    }
  }

  async login(username: string, password: string): Promise<User | null> {
    try {
      const token = await login({ login: username, password });
      if (!token) return null;
      userStore.setState({ token });
      const me = await this.getMe();
      return me;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      return null;
    }
  }

  async logout(): Promise<null> {
    try {
      await logout();
    } catch (err) {
      Sentry.captureException(err);
    }

    userStore.getState().logout();
    redirect('/');
    return null;
  }
}

export const userManager = new UserManager();
setInterval(userManager.refresh, ms(REFRESH_DELAY));

if (config.ENV !== ENV.DEV) {
  userManager.refresh();
}

export default userManager;
