import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Box, Paper, Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useGetTree } from '@/api/tree/get-tree';
import { updateTree } from '@/api/tree/update-tree';
import { Badge } from '@/component/Badge';
import { ChatRoom } from '@/component/ChatRoom/ChatRoom';
import { Container } from '@/component/Container/Container';
import { HistoryList, HistoryListFilter } from '@/component/HistoryList/HistoryList';
import { Popup } from '@/component/Popup/Popup';
import Suspense from '@/component/Suspense';
import { useConfirm } from '@/context/ConfirmContext';
import { ChatType, HistoryAction, TreeStatus } from '@/enum';
import { useChatMessageNumber } from '@/hook/useChatMessageNumber';

import { TreeInfo, TreeSizeForm, TreeStatusGraph } from './component';

type Props = {
  treeId: string;
  onClose: () => any;
};

enum ETab {
  Info = 'info',
  Size = 'size',
  Chat = 'chat',
  History = 'history',
}

export default function TreePopup({ treeId, onClose }: Props) {
  /**
   * popupKey for enable autofocus on treeId tab
   */
  const [tab, setTab] = useState<ETab>(ETab.Size);
  const [popupKey, setPopupKey] = useState<string>(`${treeId}_no`);
  const { data: tree, mutate } = useGetTree(treeId);
  const nbChatMessages = useChatMessageNumber(ChatType.Tree, treeId);
  const confirm = useConfirm();

  const hasAskReceipt = useRef<boolean>(false);
  useEffect(() => {
    if (hasAskReceipt.current) return;
    if (!tree) return;
    if (tree.status !== TreeStatus.Ordered) return;
    hasAskReceipt.current = true;
    confirm(`Avez-vous reçu cet arçon ?`).then(async (ok) => {
      if (ok) {
        await updateTree(tree?.id, { status: TreeStatus.Stocked });
        mutate({ ...tree, status: TreeStatus.Stocked });
      }
      setPopupKey(`${tree?.id}_${ok}`);
    });
  }, [confirm, mutate, tree]);

  const alreadySetTab = useRef<boolean>(false);
  useEffect(() => {
    if (alreadySetTab.current) return;
    if (!tree) return;
    if (tree.status !== TreeStatus.Stocked) return;
    alreadySetTab.current = true;
    setTab(ETab.Size);
  }, [tree]);

  const filters: HistoryListFilter[] = useMemo(() => {
    const res: HistoryListFilter[] = [];
    if (tree?.id) {
      res.push({ relationIds: [tree.id] });
    }

    if (tree?.purchaseOrderProductId) {
      res.push({ relationIds: [tree.purchaseOrderProductId] });
    }

    if (tree?.purchaseOrderId) {
      res.push({
        relationIds: [tree.purchaseOrderId],
        actions: [HistoryAction.Create, HistoryAction.ChangeStatus, HistoryAction.Sign],
      });
    }

    return res;
  }, [tree?.id, tree?.purchaseOrderProductId, tree?.purchaseOrderId]);

  if (!tree) return null;

  return (
    <Popup
      title={`Arçon A${tree.number} | ${tree.name} `}
      open
      onSave={onClose}
      saveLabel="Fermer"
      onClose={onClose}
    >
      <Container>
        <>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ mb: 2 }}
          >
            <img src="/arcon_cropped.svg" alt="Arçon" />
            <div>
              <h1 style={{ padding: 0, margin: 0 }}>Arçon A{tree.number}</h1>
              <h2 style={{ padding: 0, margin: 0 }}>{tree?.name}</h2>
            </div>
          </Stack>

          <Box sx={{ mb: 4 }}>
            <TreeStatusGraph treeId={tree.id} />
          </Box>

          <Paper elevation={2}>
            <Tabs variant="fullWidth" value={tab} onChange={(_e, v: ETab) => setTab(v)}>
              <Tab icon={<DescriptionIcon />} value={ETab.Info} label="Infos" />
              <Tab icon={<StraightenIcon />} value={ETab.Size} label="Mesures" />

              <Tab
                sx={{ pt: 2 }}
                icon={
                  <Box sx={{ mr: 1 }}>
                    <Badge value={nbChatMessages}>
                      <InsertCommentIcon fontSize="small" />
                    </Badge>
                  </Box>
                }
                value={ETab.Chat}
                label="Commentaires"
              />
              <Tab icon={<HistoryIcon />} value={ETab.History} label="Historique" />
            </Tabs>

            {tab === ETab.Size && (
              <Suspense>
                <TreeSizeForm key={popupKey} treeId={tree.id} onClose={onClose} />
              </Suspense>
            )}
            {tab === ETab.Info && (
              <Suspense>
                <TreeInfo treeId={tree.id} />
              </Suspense>
            )}
            {tab === ETab.Chat && (
              <Box sx={{ height: '600px', position: 'relative' }}>
                <ChatRoom types={[ChatType.Tree]} docId={treeId} />
              </Box>
            )}
            {tab === ETab.History && (
              <Suspense>
                <HistoryList filters={filters} />
              </Suspense>
            )}
          </Paper>
        </>
      </Container>
    </Popup>
  );
}

export { TreePopup };
