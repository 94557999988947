import { Typography } from '@mui/material';

import { useUserLang } from '@/hook/user/useUserLang';

import { useChatStore } from '../../store/chat.store';

export function Title() {
  const title = useChatStore((s) => s.chat?.title);
  const lang = useUserLang();

  if (!title) return null;

  return <Typography variant="h6">{title[lang]}</Typography>;
}
