import PercentIcon from '@mui/icons-material/Percent';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from '@/component/Tooltip/Tooltip';
import { UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user/useUserHasSomeRole';
import { I18nNs } from '@/util/i18n';

type Props = {
  id: string;
};

export function HistoryRelationVat({ id }: Props) {
  const { t } = useTranslation(I18nNs.History);
  const hasSomeRole = useUserHasSomeRole();

  const to = useMemo(() => {
    if (hasSomeRole(UserRole.God, UserRole.Price)) return `/price/vat/edit/${id}`;
    return null;
  }, [hasSomeRole, id]);

  if (!to) return null;

  return (
    <Tooltip title={t('btn.relation.show.vat')}>
      <IconButton component={Link} to={to} size="small">
        <PercentIcon />
      </IconButton>
    </Tooltip>
  );
}
