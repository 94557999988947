import PersonIcon from '@mui/icons-material/Person';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Avatar, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import upperFirst from 'lodash/upperFirst';
import { useTranslation } from 'react-i18next';

import { ListHistoriesOutput } from '@/api/history/list-histories/list-histories.output';
import { useLang } from '@/hook/useLang';
import { getDateTimeString } from '@/util/date';
import { I18nNs } from '@/util/i18n';

import { HistoryRelation } from './HistoryRelation';

type Props = {
  history: ListHistoriesOutput['docs'][0];
};

export function HistoryListItem({ history }: Props) {
  const { t } = useTranslation(I18nNs.History);
  const lang = useLang();

  return (
    <TimelineItem key={history.id}>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          {history.avatar ? (
            <Avatar sx={{ width: 40, height: 40 }} src={history.avatar} />
          ) : (
            <PersonIcon sx={{ width: 40, height: 40 }} />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span">
          {t(`enum.historyEntity.${history.entity}`)} - {t(`enum.historyAction.${history.action}`)}
        </Typography>

        <Typography sx={{ m: 'auto 0' }} align="left" variant="body2" color="text.secondary">
          {upperFirst(getDateTimeString(history.createdAt, lang))}
        </Typography>

        <Typography>
          {t(
            `message.${history.entity}.${history.action}` as string,
            history.data as any,
          ).toString()}
        </Typography>

        <Box sx={{ ml: -1 }}>
          <HistoryRelation history={history} />
        </Box>
        {/* <code>
          message.{history.entity}.{history.action}
        </code> */}
      </TimelineContent>
    </TimelineItem>
  );
}
