import StorefrontIcon from '@mui/icons-material/Storefront';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from '@/component/Tooltip/Tooltip';
import { UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user/useUserHasSomeRole';
import { useUserResellerId } from '@/hook/user/useUserResellerId';
import { I18nNs } from '@/util/i18n';

type Props = {
  id: string;
};

export function HistoryRelationResellerBy({ id }: Props) {
  const { t } = useTranslation(I18nNs.History);
  const hasSomeRole = useUserHasSomeRole();
  const resellerId = useUserResellerId();

  const to = useMemo(() => {
    if (!resellerId) return null;
    if (hasSomeRole(UserRole.God)) return `/admin/reseller/show/${id}/reseller-info`;
    if (hasSomeRole(UserRole.AdminReseller)) return `/reseller/config`;
    return null;
  }, [hasSomeRole, id, resellerId]);

  if (!to) return null;

  return (
    <Tooltip title={t('btn.relation.show.reseller_by')}>
      <IconButton component={Link} to={to} size="small">
        <StorefrontIcon color="secondary" />
      </IconButton>
    </Tooltip>
  );
}
