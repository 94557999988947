import { Outlet, Route, Routes } from 'react-router-dom';

import Suspense from '@/component/Suspense';
import lazy from '@/util/lazy';

const AdminRouter = lazy(() => import('./component/AdminRouter'));
const ChatScene = lazy(() => import('@/scene/ChatScene/ChatScene'));
const ComptaRouter = lazy(() => import('./component/ComptaRouter'));
const ConnectedLayout = lazy(() => import('@/layout/ConnectedLayout'));
const CustomerScene = lazy(() => import('@/scene/CustomerScene/CustomerScene'));
const DashboardScene = lazy(() => import('@/scene/DashboardScene/DashboardScene'));
const ForgotScene = lazy(() => import('@/scene/ForgotScene/ForgotScene'));
const LoginScene = lazy(() => import('@/scene/LoginScene/LoginScene'));
const LogoutScene = lazy(() => import('@/scene/LogoutScene/LogoutScene'));
const MeScene = lazy(() => import('@/scene/MeScene/MeScene'));
const OfflineLayout = lazy(() => import('@/layout/OfflineLayout'));
const OrderRouter = lazy(() => import('./component/OrderRouter'));
const PayScene = lazy(() => import('@/scene/PayScene'));
const PriceRouter = lazy(() => import('./component/PriceRouter'));
const ProductRouter = lazy(() => import('./component/ProductRouter'));
const PublicLayout = lazy(() => import('@/layout/PublicLayout'));
const PublicScene = lazy(() => import('@/scene/PublicScene/PublicScene'));
const PurchaseRouter = lazy(() => import('./component/PurchaseRouter'));
const RedirectScene = lazy(() => import('@/scene/RedirectScene/RedirectScene'));
const ResellerRouter = lazy(() => import('./component/ResellerRouter'));
const SearchSaddleScene = lazy(() => import('@/scene/SearchSaddleScene/SearchSaddleScene'));
const StopImpersonateScene = lazy(
  () => import('@/scene/StopImpersonateScene/StopImpersonateScene'),
);
const TestScene = lazy(() => import('@/scene/TestScene/TestScene'));
const VoucherRouter = lazy(() => import('./component/VoucherRouter'));

export function Router() {
  return (
    <Routes>
      <Route
        element={
          <Suspense>
            <Outlet />
          </Suspense>
        }
      >
        <Route element={<PublicLayout />}>
          <Route path="/redirect/:table/:id" element={<RedirectScene />} />
          <Route path="/pub/*" element={<PublicScene />} />
          <Route path="/pay/:paymentId" element={<PayScene />} />
        </Route>
        <Route element={<OfflineLayout />}>
          <Route path="/" element={<LoginScene />} />
          <Route path="/forgot/*" element={<ForgotScene />} />
        </Route>
        <Route element={<ConnectedLayout />}>
          <Route path="/test/*" element={<TestScene />} />
          <Route path="/dashboard" element={<DashboardScene />} />
          <Route path="/logout" element={<LogoutScene />} />
          <Route path="/stop-impersonate" element={<StopImpersonateScene />} />

          <Route path="/admin/*" element={<AdminRouter />} />
          <Route path="/reseller/*" element={<ResellerRouter />} />
          <Route path="/product/*" element={<ProductRouter />} />
          <Route path="/compta/*" element={<ComptaRouter />} />
          <Route path="/price/*" element={<PriceRouter />} />
          <Route path="/purchase/*" element={<PurchaseRouter />} />

          <Route path="/customer/*" element={<CustomerScene />} />
          <Route path="/order/*" element={<OrderRouter />} />
          <Route path="/voucher/*" element={<VoucherRouter />} />

          <Route path="/me/*" element={<MeScene />} />
          <Route path="/chat/*" element={<ChatScene />} />
          <Route path="/search/*" element={<SearchSaddleScene />} />
        </Route>
      </Route>
    </Routes>
  );
}
