import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';

import { ChatType } from '@/enum';
import { Room } from '@/value-object/room';

import Loader from '../Loader';
import {
  StyledChat,
  StyledChatHeader,
  StyledChatInfo,
  StyledChatInput,
  StyledChatMessages,
} from './ChatRoom.style';
import { ChatTabs, Info, Lines, Title } from './component';
import { LinkBtn } from './component/LinkBtn/LinkBtn';
import { useChat } from './hook/useChat';
import { useChatStore } from './store/chat.store';

type Props = {
  types: ChatType[];
  docId: string;
  description?: string;
  onClose?: () => any;
};

export const ChatRoom = ({ types, docId, description, onClose }: Props) => {
  const hasChat = useChatStore((s) => s.chat !== null);

  useChat();

  const key = JSON.stringify({ types, docId });
  const oldKey = useRef<string | null>(null);
  useEffect(() => {
    if (key === oldKey.current) return;
    oldKey.current = key;

    useChatStore.getState().reset();
    useChatStore.setState({
      types,
      docId,
    });
    if (types.length > 0) {
      useChatStore.setState({
        room: Room.create(types[0], docId),
      });
    }
  }, [types, docId, key]);

  return (
    <StyledChat>
      <StyledChatHeader>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <LinkBtn />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>
              <Title />
              {description && (
                <Typography variant="subtitle1" fontSize={15}>
                  {description}
                </Typography>
              )}
            </div>
            {onClose && (
              <div>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </div>
            )}
          </Stack>
        </Stack>
      </StyledChatHeader>
      {hasChat ? (
        <>
          <StyledChatMessages>
            <ChatTabs />
            <Lines />
          </StyledChatMessages>

          <StyledChatInfo>
            <Info />
          </StyledChatInfo>
        </>
      ) : (
        <Loader />
      )}

      <StyledChatInput />
    </StyledChat>
  );
};

export default ChatRoom;
